<template>
  <div>
    <b-card class="quoterCard">
      <div v-for="(container, index) of containers" class="container-quoter-rows pb-1" :key="`container${index}`" :id="`container_quoter_${index}`">
        <div class="container-quoter-number number-top">{{ index + 1 }}</div>
        <form-render :ref="`formRenderQuoter${index}`" :key="container.keyFormRender" :class="[`${container.class ? container.class : ''}`, 'fieldQuoter']" :fields="container.field" :form.sync="form">
          <template #msg="scope">
            <ul class="modal-msj mt-3 mb-0 pl-1" v-if="scope.field.name === 'msg'">
              <li>{{ scope.field.msg }}</li>
            </ul>
          </template>
        </form-render>
        <div class="container_quoter_3 mt-1">
          <div v-if="selected.text && (index + 1 === 3)" class="font-weight-bold">Representación ilustrativa de las medidas del envío</div>
          <div v-if="selected.text && (index + 1 === 3)" class="container-img"><img width="100px" :src="selected.icon" /></div>
          <div v-if="selected.text && (index + 1 === 3)">{{ selected.text }}</div>
        </div>
      </div>
      <b-button variant="warning" class="quoterButton" :disabled="loading.filter" @click="validateForm">
        <span v-if="!loading.filter" class="mb-1">Cotizar</span>
        <span class="px-2" v-if="loading.filter"><b-spinner small /> </span>
      </b-button>
      <multiple-weights-list
        id="MultipleWeightsList"
        v-if="showMultipleWeightsList && !loading.filter"
        :dataList="dataList"
        @generateArrayKgs="generateArrayKgs"
        class="style-multiple-weights-list"
        @changeTab="changeTab"
      ></multiple-weights-list>
      <one-weight-list
        id="OneWeightList"
        v-if="showOneWeightList && !loading.filter"
        :dataList="dataList"
        @generateArrayKgs="generateArrayKgs"
        class="style-multiple-weights-list"
        @changeTab="changeTab"
      ></one-weight-list>
      <div class="alert-body d-flex align-items-center" v-if="form.countryOfOrigin && isCoPeMx.includes(form.countryOfOrigin.code.toLowerCase()) && this.form.cashOnDelivery && !loading.filter">
        <ul class="mb-1 mt-1">
          <li>Los valores presentados no incluyen los impuestos asociados.</li>
        </ul>
      </div>
      <div class="table-quoter style-skeleton"  v-show="loading.filter">
        <b-skeleton height="40px" width="`calc(100vh - 2.5rem)`" class="mb-1" />
        <div class="table-skeleton-tableQuoter">
          <b-skeleton-table :rows="15" :columns="10" :table-props="{ }" />
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import weightIcon from '@/assets/images/icons/quoterIcons/Peso.png'
import lengthIcon from '@/assets/images/icons/quoterIcons/Largo.png'
import heightIcon from '@/assets/images/icons/quoterIcons/Alto.png'
import widthIcon from '@/assets/images/icons/quoterIcons/Ancho.png'
import BaseServices from '@/store/services/index'
import MultipleWeightsList from './components/MultipleWeightsList.vue'
import OneWeightList from './components/OneWeightList.vue'
import envelope from '@/assets/images/icons/quoterIcons/envelope.png'
import box_sm from '@/assets/images/icons/quoterIcons/box_sm.png'
import box_md from '@/assets/images/icons/quoterIcons/box_md.png'
import box_gd from '@/assets/images/icons/quoterIcons/box_gd.png'

export default {
  components: {MultipleWeightsList, OneWeightList},
  props: ['data'],
  data () {
    return {
      baseService: new BaseServices(this),
      containers: [{field: []}],
      fields: {},
      form: {labelOrigin: 'origin', labelDestination: 'destination'},
      optionsDimensions: [
        {id:0, text: 'Sobre (Ej. documento)', code: 'envelope', length: 20, height: 30, width: 0.2},
        {id:1, text: 'Pequeño (Ej. celular)', code: 'sm_box', length: 25, height: 15, width: 8},
        {id:2, text: 'Grande (Ej. microondas)', code: 'md_box', length: 60, height: 40, width: 30},
        {id:3, text: 'Mas Grande (Ej. lavadora)', code: 'bg_box', length: 100, height: 100, width: 120}
      ],
      fieldWeightValue: { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'weightValue', label: 'Peso total', type: 'number', validation: 'required', containerClass: 'col-sm-12 col-md-12 col-lg-6 fieldSelect field-grid', dependency: 'countryOfOrigin', append: {text: 'Kg', color: '#BBBBBB'}, change: this.hideTable, img: weightIcon},
      fieldLengthValue: { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'lengthValue', label: 'Largo', change: this.setSeletedDimensions, type: 'number', containerClass: 'col-sm-12 col-md-12 col-lg-4 fieldSelect field-grid', dependency: 'countryOfOrigin', append: {text: 'cm', color: '#BBBBBB'}, img: lengthIcon },
      fieldHeightValue: { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'heightValue', label: 'Alto', change: this.setSeletedDimensions, type: 'number', containerClass: 'col-sm-12 col-md-12 col-lg-4 fieldSelect field-grid', dependency: 'countryOfOrigin', append: {text: 'cm', color: '#BBBBBB'}, img: heightIcon },
      fieldWidthValue: { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'widthValue', label: 'Ancho', change: this.setSeletedDimensions, type: 'number', containerClass: 'col-sm-12 col-md-12 col-lg-4 fieldSelect field-grid', dependency: 'countryOfOrigin', append: {text: 'cm', color: '#BBBBBB'}, img: widthIcon },
      fieldDimensions: { fieldType: 'FieldSelect', useSkeleton: false, useLabel: false, name: 'dimensions', placeholder: 'Selecciona una opción', label: ' ', containerClass: 'col-sm-12 col-md-12 col-lg-12 mb-1 mt-0 fieldSelect pr-0', dependency: 'countryOfOrigin', options: this.optionsDimensions, change: this.changeDimensions, sort: true},
      fieldDeclaredValue: { fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'declaredValue', label: 'Valor declarado', type: 'number', validation: 'required', containerClass: 'col-sm-12 col-md-12 col-lg-6 fieldSelect pr-0', dependency: 'countryOfOrigin', change: this.changeDeclaredValue, prepend: {} },
      fieldCashOnDelivery: { fieldType: 'FieldCheckbox', useSkeleton: false, name: 'cashOnDelivery', align: 'h', options: [{ id: 'cashOnDelivery', text: 'Agregar recaudo contra entrega' }], containerClass: 'col-sm-12 col-md-6 col-lg-6 mt-3 mb-1 fieldSelect', change: this.changeCashOnDelivery },
      fieldMsgDimensions: { name: 'msg', msg: 'Edite las dimensiones manualmente o seleccione un tamaño estandar en el selector', useLabel: false, useSlot: true, containerClass: 'col-12' },
      fieldAmountToCollect: {fieldType: 'FieldInput', useSkeleton: false, useLabel: true, name: 'amountToCollect', label: 'Monto a recaudar', type: 'number', validation: 'required', containerClass: 'col-sm-12 col-md-6 col-lg-6 mt-1 fieldSelect pr-0', prepend: {} },
      fieldSkipLine: { name: 'line', useSkeleton: true, skipLine: true, containerClass: 'col-12 mt-1' },
      isCoPeMx: ['co', 'pe', 'mx'],
      loading: {
        filter: false
      },
      tabIndex: 0,
      dataList: {},
      currentKgs: { from: 1, size: 7 },
      allData: [],
      selected: {icon: null, text: null},
      volumetricWeightKgs: undefined,
      showMultipleWeightsList: false,
      showOneWeightList: false
    }
  },
  computed: {
    ...mapGetters({
      servicesTypes: 'getServiceTypesQuoter',
      pricing: 'getPricingQuoter'
    })
  },
  watch: {
    servicesTypes() {
      if (this.form.cashOnDelivery) {
        this.arrayServicesTypes = this.servicesTypes.rows.filter(el => el.type !== 'express' && el.type !== 'international')
      } else if (this.form.countryOfOrigin?.code !== this.form.countryOfDestination?.code) {
        this.arrayServicesTypes = this.servicesTypes.rows.filter(el => el.type === 'international')
      } else {
        this.arrayServicesTypes = this.servicesTypes.rows.filter(el => el.type !== 'international')
      }
      this.setServicesTypes()
    },
    'currentKgs.from' () {
      this.searchPrices()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      this.fields.origin = [
        { name: 'labelOrigin', label: this.$t('Origen'), useSlot: true, containerClass: 'col-sm-12 label-countries'},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: false, name: 'countryOfOrigin', disabled: false, validation: 'required', containerClass: 'formQuoter_country fieldSelect', change: this.changeCountryOfOrigin, addFlags: true, sort: true, placeholder: 'País', clearable: false, onlyFlag: true, options: this.data.countries },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: false, name: 'origin', id: 'origin', disabled: false, validation: 'required', containerClass: 'formQuoter_origin fieldSelect', dependency: 'countryOfOrigin', clearable: true, searchOnType: { fx: this.setPlaceOrigin, nChars: 3, debounce: 600, persistSearch: true }, sort: true, change: this.hideTable}
      ]
    
      this.fields.destination = [
        { name: 'labelDestination', label: this.$t('Destino'), useSlot: true, containerClass: 'col-sm-12 label-countries'},
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: false, name: 'countryOfDestination', disabled: false, validation: 'required', containerClass: 'formQuoter_country fieldSelect', change: this.changeCountryOfDestination, addFlags: true, sort: true, placeholder: 'País', clearable: false, onlyFlag: true, options: Array.isArray(this.data.allCountries) ? this.data.allCountries.filter(el => el.code === 'MX' || el.code === 'US') : [] },
        { fieldType: 'FieldSelect', useSkeleton: false, useLabel: false, name: 'destination', disabled: false, validation: 'required', containerClass: 'formQuoter_destination fieldSelect', dependency: 'countryOfDestination', clearable: true, searchOnType: { fx: this.setPlaceDestination, nChars: 3, debounce: 600, persistSearch: false, allowSearchAsValue: true }, sort: true, change: this.hideTable}
      ]
      this.fields.stepThree = [
        this.fieldWeightValue, this.fieldSkipLine, this.fieldLengthValue, this.fieldHeightValue, this.fieldWidthValue
      ]
      this.containers = [
        {field: this.fields.origin, keyFormRender: 0, name: 'origin', class: 'container-countries'},
        {field: this.fields.destination, keyFormRender: 1, name: 'destination', class: 'container-countries'},
        {field: this.fields.stepThree, keyFormRender: 2, name: 'stepThree'}
      ]
      this.isSeller()
    },
    setFieldsForCountry(country) {
      this.fieldDimensions.options = this.optionsDimensions
      switch (country.code.toLowerCase()) {
      case 'cl':
        this.fields.stepThree = [this.fieldWeightValue, this.fieldSkipLine, this.fieldLengthValue, this.fieldHeightValue, this.fieldWidthValue]
        break
      case 'co':
        this.fields.stepThree = [this.fieldDeclaredValue, this.fieldWeightValue, this.fieldMsgDimensions, this.fieldDimensions, this.fieldSkipLine, this.fieldLengthValue, this.fieldHeightValue, this.fieldWidthValue, this.fieldSkipLine, this.fieldCashOnDelivery]
        break
      case 'mx':
        this.fields.stepThree = [this.fieldWeightValue, this.fieldSkipLine, this.fieldLengthValue, this.fieldHeightValue, this.fieldWidthValue, this.fieldSkipLine, this.fieldCashOnDelivery]
        break
      case 'pe':
        this.fields.stepThree = [this.fieldWeightValue, this.fieldMsgDimensions, this.fieldDimensions, this.fieldSkipLine, this.fieldLengthValue, this.fieldHeightValue, this.fieldWidthValue, this.fieldSkipLine, this.fieldCashOnDelivery]
        break
      }
    },
    setPlaceOrigin(value) {
      return this.setPlaces(value, this.form.countryOfOrigin.id)
    },
    setPlaceDestination(value) {
      return this.setPlaces(value, this.form.countryOfDestination.id)
    },
    async setPlaces(value, countryId) {
      const params = { country_id: countryId }
      const queryParams = { search: value }
      return await this.baseService.callService('getPlacesQuoter', queryParams, params)
        .then(resp => { return resp.data.places.map(el => ({...el, text: el.full_name, valid: true})) })
        .catch(err => { return err })
    },
    setAmountToCollectForColombia() {
      if (this.form.countryOfOrigin.code.toLowerCase() === 'co' && this.form.cashOnDelivery) {
        this.m_setSkeletonField(this.fields.stepThree, 'name', 'amountToCollect', true)
        this.form.amountToCollect = this.form.declaredValue
        setTimeout(() => { this.m_setSkeletonField(this.fields.stepThree, 'name', 'amountToCollect', false) }, 10)
      } else {
        delete this.form.amountToCollect
      }
    },
    setServicesTypes() {
      this.tabIndex = this.m_getElementIndex(this.arrayServicesTypes, 'type', 'nextday') === -1 ? 0 : this.m_getElementIndex(this.arrayServicesTypes, 'type', 'nextday')
      this.filterPrices()
    },
    setdataListList(data) {
      this.dataList.data = data
      this.dataList.form = this.form
      this.dataList.currentKgs = this.currentKgs
      this.dataList.arrayCurrentKgs = this.arrayCurrentKgs
      this.dataList.servicesTypes = this.arrayServicesTypes
      this.dataList.tabIndex = this.tabIndex
      this.dataList.volumetricWeightKgs = this.volumetricWeightKgs
    },
    setSeletedDimensions () {
      this.hideTable()
      const volumetricWeight = this.form.lengthValue * this.form.heightValue * this.form.widthValue / 3
      if (volumetricWeight > 0 && volumetricWeight <= 40) {
        this.selected.icon = envelope
        this.selected.text = 'Sobre (Ej. documento)'
      } else if (volumetricWeight > 40 && volumetricWeight <= 1000) {
        this.selected.icon = box_sm
        this.selected.text = 'Pequeño (Ej. celular)'
      } else if (volumetricWeight > 1000 && volumetricWeight <= 25000) {
        this.selected.icon = box_md
        this.selected.text = 'Grande (Ej. microondas)'
      } else if (volumetricWeight > 25000) {
        this.selected.icon = box_gd
        this.selected.text = 'Mas Grande (Ej. lavadora)'
      } else {
        this.selected.icon = null
        this.selected.text = null
      }
    },
    changeCountryOfOrigin(name, value) {
      delete Object.keys(this.form).forEach(el => { if (el !== 'countryOfOrigin') delete this.form[el] })
      if (this.containers[this.m_getElementIndex(this.containers, 'name', 'stepThree')]) this.containers.pop()
      this.hideTable()
      this.setSeletedDimensions()
      this.resetFields(this.fields.origin, ['origin'])
      this.resetFields(this.fields.destination, ['destination'])
      this.fieldDeclaredValue.prepend = {text: this.$options.filters.currencySymbol(this.form.countryOfOrigin.code)}
      this.fieldAmountToCollect.prepend = {text: this.$options.filters.currencySymbol(this.form.countryOfOrigin.code)}
      this.setFieldsForCountry(value)
      this.containers.push({field: this.fields.stepThree, keyFormRender: 2, name: 'stepThree'})
      if (value.code.toLowerCase() !== 'mx') {
        this.form.countryOfDestination = value
        this.fields.destination[this.m_getElementIndex(this.fields.destination, 'name', 'countryOfDestination')].disabled = true
      } else {
        delete this.form.countryOfDestination
        delete this.fields.destination[this.m_getElementIndex(this.fields.destination, 'name', 'countryOfDestination')].disabled
      }
    },
    changeCountryOfDestination(name, value) {
      this.hideTable()
      if (value.code.toLowerCase() === 'us' && this.fields.stepThree.findIndex(el => el.name === 'cashOnDelivery') !== -1) {
        this.fields.stepThree.pop()
      } else if (value.code.toLowerCase() !== 'us' && this.fields.stepThree.findIndex(el => el.name === 'cashOnDelivery') === -1) {
        this.fields.stepThree.push(this.fieldCashOnDelivery)
      }
      this.resetFields(this.fields.destination, ['destination'])
      delete this.form.destination
    },
    changeCashOnDelivery(name, value) {
      this.hideTable()
      if (value) {
        this.fieldAmountToCollect.disabled = !!(this.form.countryOfOrigin.code.toLowerCase() === 'co')
        this.fields.stepThree.push(this.fieldAmountToCollect)
      } else if (this.fields.stepThree[this.m_getElementIndex(this.fields.stepThree, 'name', 'amountToCollect')]) {
        this.fields.stepThree.pop()
      }
      this.setAmountToCollectForColombia()
    },
    changeDeclaredValue(name, value) {
      this.hideTable()
      this.setAmountToCollectForColombia()
    },
    changecurrentKgs ({from}) {
      this.currentKgs.from = from
    },
    changeDimensions (name, value) {
      this.hideTable()
      if (value) {
        this.form.lengthValue = value.length
        this.form.heightValue = value.height
        this.form.widthValue = value.width
      } else {
        Object.keys(this.form).forEach(el => { if (el === 'lengthValue' || el === 'heightValue' || el === 'widthValue') this.form[el] = undefined })
      }
      this.setSeletedDimensions()
    },
    isSeller() {
      if (this.$session.get('cas_user').role !== 'admin') {
        this.form.countryOfOrigin = this.$session.get('cas_user').country
        this.changeCountryOfOrigin('countryOfOrigin', this.$session.get('cas_user').country)
        this.fields.origin[this.m_getElementIndex(this.fields.origin, 'name', 'countryOfOrigin')].disabled = true
      }
    },
    async validateForm () {
      const value1 = await this.$refs.formRenderQuoter0[0]?.onlyCheckForm() // check origin
      const value2 = await this.$refs.formRenderQuoter1[0]?.onlyCheckForm() // check destination
      const value3 = await this.$refs.formRenderQuoter2[0]?.onlyCheckForm() // check stepThree
      const allValues = [value1 && value2 && value3]
      const isCheck = allValues.every(el => el === true)
      if (isCheck) this.getServiceTypes()
    },
    getServiceTypes() {
      this.loading.filter = true
      const params = { country_id: this.form.countryOfOrigin.id }
      this.$store.dispatch('fetchService', { name: 'getServiceTypesQuoter', params })
    },
    getBaseQueryParams (serviceType) {
      const queryParams = {
        country: this.form.countryOfOrigin.code,
        from_place: this.form.origin.name,
        from_place_code: this.form.origin.code,
        to_place: this.form.destination.name,
        to_place_code: this.form.destination.code,
        show_carriers: true,
        type: serviceType?.type,
        weight: this.form.weightValue,
        length: this.form.lengthValue || 0,
        width: this.form.widthValue || 0,
        height: this.form.heightValue || 0,
        country_to: this.form.countryOfDestination.code
      }
      if (this.form.cashOnDelivery) queryParams.with_cod_price = this.form.cashOnDelivery
      if (this.isCoPeMx.includes(this.form.countryOfOrigin.code.toLowerCase()) && this.form.amountToCollect) {
        queryParams.declared_value = this.form.amountToCollect
      } else if (this.form.countryOfOrigin.code.toLowerCase() === 'co' && !this.form.amountToCollect) {
        queryParams.declared_value = this.form.declaredValue
      }
      return queryParams
    },
    filterPrices() {
      this.calculateVolumetricWeight()
      this.weightCompares()
      const prevIndex = this.tabIndex
      this.showTabs = true
      if (prevIndex === this.tabIndex || prevIndex === null) {
        this.searchPrices()
      }
    },
    calculateVolumetricWeight () {
      this.m_setSkeletonField(this.fields.stepThree, 'name', 'dimensions', true)
      this.volumetricWeight = false
      if (this.form?.lengthValue && this.form?.heightValue && this.form?.widthValue) {
        if (this.form.countryOfOrigin?.code === 'CL') {
          this.volumetricWeightKgs = parseFloat(((this.form.lengthValue * this.form.heightValue * this.form.widthValue) / 4000)).toFixed(1)
        } else if (this.form.countryOfOrigin?.code === 'MX') {
          this.volumetricWeightKgs = parseFloat(((this.form.lengthValue * this.form.heightValue * this.form.widthValue) / 5000)).toFixed(1)
        }
      } else {
        this.volumetricWeightKgs = 0
      }
      this.volumetricWeightValue = this.form.lengthValue * this.form.heightValue * this.form.widthValue / 3
      if (this.volumetricWeightValue > 0 && this.volumetricWeightValue <= 40) {
        this.form.dimensions = this.optionsDimensions[this.m_getElementIndex(this.optionsDimensions, 'id', 0)]
      } else if (this.volumetricWeightValue > 40 && this.volumetricWeightValue <= 1000) {
        this.form.dimensions = this.optionsDimensions[this.m_getElementIndex(this.optionsDimensions, 'id', 1)]
      } else if (this.volumetricWeightValue > 1000 && this.volumetricWeightValue <= 25000) {
        this.form.dimensions = this.optionsDimensions[this.m_getElementIndex(this.optionsDimensions, 'id', 2)]
      } else if (this.volumetricWeightValue > 25000) {
        this.form.dimensions = this.optionsDimensions[this.m_getElementIndex(this.optionsDimensions, 'id', 3)]
      } else {
        this.form.dimensions = null
      }
      this.m_setSkeletonField(this.fields.stepThree, 'name', 'dimensions', false)
    },
    weightCompares() {
      if (this.arrayServicesTypes && this.arrayServicesTypes[this.tabIndex].type === 'express') {
        this.calcCurrentKgs(3)
      } else if (this.form.weightValue < parseFloat(this.volumetricWeightKgs)) {
        this.volumetricWeight = true
        this.calcCurrentKgs(Math.ceil(this.volumetricWeightKgs))
      } else this.calcCurrentKgs(this.form.weightValue)
      this.generateArrayKgs()
    },
    calcCurrentKgs (weight) {
      this.currentKgs.from = parseInt(weight) <= 3 ? 1 : parseInt(weight) - 3
    },
    generateArrayKgs(val) {
      if (val) this.currentKgs.from = val.from
      this.arrayCurrentKgs = []
      if (this.arrayServicesTypes[this.tabIndex].type !== 'express' && (this.form.countryOfOrigin.code === 'CO' || this.form.countryOfOrigin.code === 'PE' || (this.form.countryOfOrigin.code === 'MX' && this.form.cashOnDelivery))) {
        this.form.weightValue < parseFloat(this.volumetricWeightKgs) ? this.arrayCurrentKgs.push(parseFloat(this.volumetricWeightKgs)) : this.arrayCurrentKgs.push(this.form.weightValue)
      } else {
        for (let index = this.currentKgs.from; index < this.currentKgs.from + this.currentKgs.size; index++) {
          if (index === Math.ceil(this.volumetricWeightKgs) && (this.form.weightValue < parseFloat(this.volumetricWeightKgs))) {
            this.arrayCurrentKgs.push(parseFloat(this.volumetricWeightKgs))
          } else {
            this.arrayCurrentKgs.push(index)
          }
        }
      }
    },
    searchPrices () {
      this.loading.filter = true
      this.showMultipleWeightsList = false
      this.showOneWeightList = false
      if (!this.arrayServicesTypes) return null
      const services = []
      const queryParams = this.getBaseQueryParams(this.arrayServicesTypes[this.tabIndex])
      if (this.form.countryOfOrigin.code === 'CL' && this.arrayServicesTypes[this.tabIndex].type === 'doc') {
        queryParams.weight = 0.5
        services.push({ name: 'getPricingQuoter', queryParams: {...queryParams} })
      } else {
        this.arrayCurrentKgs.map(el => {
          if (this.arrayServicesTypes[this.tabIndex].type === 'express') queryParams.km_distance = el
          queryParams.weight = el
          services.push({ name: 'getPricingQuoter', queryParams: {...queryParams} })
        })
      }
      this.$store.dispatch('fetchMultipleServices', {
        services,
        onSuccess: (data) => {
          this.setdataListList(data)
          this.loading.filter = false
          if (this.form.countryOfOrigin.code === 'CL' || (this.form.countryOfOrigin.code === 'MX' && !this.form.cashOnDelivery) || this.arrayServicesTypes[this.tabIndex].type === 'express') {
            this.showMultipleWeightsList = true
            this.showOneWeightList = false
            this.scrollToTable('MultipleWeightsList')
          } else {
            this.showMultipleWeightsList = false
            this.showOneWeightList = true
            this.scrollToTable('OneWeightList')
          }
        },
        onError: err => this.$newError('Ocurrio un error.', err.message, 'large')
      })
    },
    goToNew() {
      const idCashOnDelivery = document.getElementById('cashOnDelivery')
      if (idCashOnDelivery) idCashOnDelivery.scrollIntoView({ behavior: 'smooth', block: 'start' })
    },
    changeTab (tabIndex) {
      this.tabIndex = tabIndex
      this.loading.filter = true
      this.weightCompares()
      this.searchPrices()
    },
    scrollToTable(id) {
      const table = document.getElementById(id)
      if (table) {
        table.scrollIntoView({ behavior: 'smooth', block: 'start' })
      } else {
        setTimeout(() => {
          this.scrollToTable(id)
        }, 100)
      }
    },
    resetFields(array, arrayValToReset) {
      arrayValToReset.forEach(el => {
        this.m_setSkeletonField(array, 'name', el, true)
      })
      setTimeout(() => {
        arrayValToReset.forEach(el => {
          this.m_setSkeletonField(array, 'name', el, false)
        })
      }, 10)
    },
    hideTable() {
      this.showMultipleWeightsList = false
      this.showOneWeightList = false
    }
  }
}
</script>
<style lang="scss">
  .style-multiple-weights-list {
    margin-top: 4em;
  }
  .style-skeleton {
    margin-top: 8em
  }
  .newFeature {
    background-color: #E6EFFA;
    border-radius: 10px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #B9D5F4;
    width: fit-content;
  }
  .button-new-feature {
    color: #0169DE;
  }
  .formQuoter_country {
    padding-left: 1em;
    padding-right: 0;
    div {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .formQuoter_destination, .formQuoter_origin {
    div {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
  .container_quoter_3 {
    text-align: center;
    width: 32%;
    padding-left: 4%;
  }
  @media screen and (max-width: 960px) {
    .container_quoter_3 {
      text-align: center !important;
      display: grid;
      position: relative;
      width: 100%;
      padding-top: 4%;
    }
    .container-quoter-rows {
      display: grid;
    }
  }
  #origen > .b-skeleton {
    display: none !important;
  }
  .field-grid {
    display: grid !important;
    grid-template-columns: 60px auto;
    padding-right: 0 !important;
  }
  .container-countries .row {
  display: grid;
  grid-template-columns: 7em minmax(0, 1fr);
  }
  .label-countries {
    grid-column: 1 / 4;
  }
  .formQuoter_country{
    grid-column: 2 / 1;
  }
  .formQuoter_origin, .formQuoter_destination{
    grid-column: 2 / 4;
  }
</style>
